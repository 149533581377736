.hide-on-mobile {
    @media (max-width: 768px) {
        display: none !important;
    }
}
  
.hide-on-desktop {
    @media (min-width: 769px) {
        display: none !important;
    }
}