ion-button {
    --border-radius: 15px;
    --border-color: #707070;
    --padding-bottom: 1rem;
    --padding-left: 1rem;
    --padding-right: 1rem;
    --padding-top: 1rem;
      min-height: 70px !important;
  --border-width: 0px;
      --background-hover: var(--ion-color-secondary);

    
}
.button-large {
    height: 100px;
}
.button-full {
   width: 100%;
}
.button-inner {
    flex-direction: column;
}

ion-button.button-outline:hover {
    background: var(--ion-color-secondary);
    border: none;
    border-radius: 15px;
    color: white!important;
}
ion-button.button-outline  {
        border: 1px solid;
        border-radius: 15px;
}

.ion-color-tertiary {
    color: #707070!important;
}
