ion-input {
  --color: white !important;
}
ion-item {
  ion-input {
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    height: 70px;

    --background: transparent;
    --padding-start: 20px !important;
    --padding-end: 20px !important;

    &.default {
      --color: var(--ion-color-primary-tint);
      --placeholder-color: var(--ion-color-primary-tint);
      border: solid 1px var(--ion-color-tertiary);
      border-radius: 15px;
    }
    &.onboarding-default {
      --color: var(--ion-color-primary-tint);
      --placeholder-color: var(--ion-color-primary-tint);
      border: solid 1px #687984;
      border-radius: 15px;
    }

    &.filled {
      --color: #FFFFFF;
      --placeholder-color: var(--ion-color-primary-tint);
      border: solid 1px var(--ion-color-primary-tint);
      border-radius: 15px;
    }

    &.focused {
      --color: #FFFFFF;
      --placeholder-color: #FFFFFF;
      border: solid 1px #43caf1;
      border-radius: 15px;
    }

    &.error {
      --color: #FFFFFF;
      --placeholder-color: #FFFFFF;
      border: solid 1px var(--ion-color-danger);
      border-radius: 15px;
    }
  }
}

div.input-wrap {
  ion-input {
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    --background: transparent;
    --padding-start: 20px !important;
    --padding-end: 20px !important;
        height: 100%;
        
    &.default {
      --color: var(--ion-color-primary-tint);
      --placeholder-color: var(--ion-color-primary-contrast);
      border: solid 1px var(--ion-color-tertiary);
      border-radius: 15px;
      margin: 18px 0;
    }

    &.error {
      --color: #FFFFFF;
      --placeholder-color: #FFFFFF;
      border: solid 1px var(--ion-color-danger);
      border-radius: 15px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
