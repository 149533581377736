/**
 * general
 */
 $border-radius: 4px;
 $border-radius-alt: 40px;
 
 /**
  * spacing
  */
 $default-spacing: 40;
 $default-spacing-sm: 20;
 
 /**
  * ionic
  */
 :root {
 }
 
 /**
  * viewport sizes
  */
 $sm: "min-width: 321px";
 $md: "min-width: 721px";
 $md-vert: "min-height: 635px";
  
 $sm-down: "max-width: 320px";
 $md-down: "max-width: 720px";
  
 $sm-vert-down: "max-height: 553px";
 $md-vert-down: "max-height: 634px";
