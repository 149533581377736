// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@import "../app/scss/settings";

/** Ionic CSS Variables **/
:root {

  --ion-background-color: #111111;
  --ion-background-color-rgb: 49, 68, 82;

  --ion-toolbar-color: #1d1d23;
  --ion-color-white: #ffffff;
  /** primary **/
  --ion-color-primary: #111111;
  --ion-color-primary-rgb: 37, 52, 62;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #314452;
  --ion-color-primary-tint: #BBC5D1;

  /** secondary **/
  --ion-color-secondary: #43CAF1;
  --ion-color-secondary-rgb: 67, 202, 241;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0094b9;
  --ion-color-secondary-tint: #88ffff;

  /** tertiary **/
  --ion-color-tertiary: #707070;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #89C81D;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #72a718;
  --ion-color-success-tint: #5d8813;

  /** warning **/
  --ion-color-warning: #ED5C29;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #ebaa20;
  --ion-color-warning-tint: #aa7c19;

  /** danger **/
  --ion-color-danger: #43CAF1;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade:rgb(207, 82, 36)9;
  --ion-color-danger-tint: #a8421d;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #ffffff;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #a0a0a0;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-gray-cloud: #FCFDFF;
  --ion-color-gray-athens: #F4F5F7;
  --ion-color-gray-greek: #E9EAED;
  --ion-color-gray-dusty: #999999;
  --ion-color-gray-dove: #737477;
  --ion-color-gray-abbey: #58595B;
  --ion-color-gray-charchoal: #4D4E50;
  --ion-color-gray-knight: #3d3d3d;
  --ion-color-blue-cerulean: #00adee;
  --ion-color-blue-malibu: #59AFFA;
  --ion-color-yellow-galliano: #DEC50B;
  --ion-color-yellow-lemon: #F7DD0C;
  --ion-color-red-ribbon: #EE0050;
  --ion-color-pink-dirty: #FFDBDB;
  --ion-color-pink-sky: #FFF4F4;
  --ion-color-green-mint: #0CF7B6;
  --ion-color-green-niagara: #09B886;
  --ion-color-purple-mortar: #4C4450;
  --ion-color-orange-carrot: #F49021;
  --ion-color-orange-soapstone: #FFFAF7;
  --ion-color-orange-hotcinnamon: #DF6A2B;

  --global-horizontal-padding: 35px;
  --global-vertical-padding: 35px;

  --sidebar-width: 300px;



  /* New UI */

  --ion-custom-primary: #25343E

}
:root {

  /** vault color palette **/
  --ion-color-vault: #43CAF1;
  --ion-color-vault-rgb: 18,122,211;
  --ion-color-vault-contrast: #ffffff;
  --ion-color-vault-contrast-rgb: 255,255,255;
  --ion-color-vault-shade: #106bba;
  --ion-color-vault-tint: #2a87d7;

  /** trade color palette **/
  --ion-color-trade: #707070;
  --ion-color-trade-rgb: 68,181,30;
  --ion-color-trade-contrast: #ffffff;
  --ion-color-trade-contrast-rgb: 0,0,0;
  --ion-color-trade-shade: #3c9f1a;
  --ion-color-trade-tint: #57bc35;

  /** Prime color palette **/
  --ion-color-prime: #43CAF1;
  --ion-color-prime-rgb: 250,167,16;
  --ion-color-prime-contrast: #ffffff;
  --ion-color-prime-contrast-rgb: 0,0,0;
  --ion-color-prime-shade: #dc930e;
  --ion-color-prime-tint: #fbb028;

  --ion-color-wallet: #212322;
  --ion-color-lode: #ff8C00;
}




.ion-color-vault {
  --ion-color-base: var(--ion-color-vault);
  --ion-color-base-rgb: var(--ion-color-vault-rgb);
  --ion-color-contrast: var(--ion-color-vault-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vault-contrast-rgb);
  --ion-color-shade: var(--ion-color-vault-shade);
  --ion-color-tint: var(--ion-color-vault-tint);
}

.ion-color-trade {
  --ion-color-base: var(--ion-color-trade);
  --ion-color-base-rgb: var(--ion-color-trade-rgb);
  --ion-color-contrast: var(--ion-color-trade-contrast);
  --ion-color-contrast-rgb: var(--ion-color-trade-contrast-rgb);
  --ion-color-shade: var(--ion-color-trade-shade);
  --ion-color-tint: var(--ion-color-trade-tint);
}

.ion-color-wallet {
  --ion-color-base: var(--ion-color-wallet);
}
.ion-color-lode {
  --ion-color-base: var(--ion-color-lode);
}

.ion-color-prime {
  --ion-color-base: var(--ion-color-prime);
  --ion-color-base-rgb: var(--ion-color-prime-rgb);
  --ion-color-contrast: var(--ion-color-prime-contrast);
  --ion-color-contrast-rgb: var(--ion-color-prime-contrast-rgb);
  --ion-color-shade: var(--ion-color-prime-shade);
  --ion-color-tint: var(--ion-color-prime-tint);
}



:root {

  /** vault color palette **/
	--ion-color-vault: #43CAF1;
	--ion-color-vault-rgb: 18,122,211;
	--ion-color-vault-contrast: #ffffff;
	--ion-color-vault-contrast-rgb: 255,255,255;
	--ion-color-vault-shade: #106bba;
	--ion-color-vault-tint: #2a87d7;

  /** trade color palette **/
  --ion-color-trade: #43CAF1;
	--ion-color-trade-rgb: 68,181,30;
	--ion-color-trade-contrast: #ffffff;
	--ion-color-trade-contrast-rgb: 0,0,0;
	--ion-color-trade-shade: #3c9f1a;
	--ion-color-trade-tint: #57bc35;

  /** Prime color palette **/
  --ion-color-prime: #43CAF1;
	--ion-color-prime-rgb: 250,167,16;
	--ion-color-prime-contrast: #ffffff;
	--ion-color-prime-contrast-rgb: 0,0,0;
	--ion-color-prime-shade: #dc930e;
	--ion-color-prime-tint: #fbb028;
}

.ion-color-vault {
	--ion-color-base: var(--ion-color-vault);
	--ion-color-base-rgb: var(--ion-color-vault-rgb);
	--ion-color-contrast: var(--ion-color-vault-contrast);
	--ion-color-contrast-rgb: var(--ion-color-vault-contrast-rgb);
	--ion-color-shade: var(--ion-color-vault-shade);
	--ion-color-tint: var(--ion-color-vault-tint);
}

.ion-color-trade {
	--ion-color-base: var(--ion-color-trade);
	--ion-color-base-rgb: var(--ion-color-trade-rgb);
	--ion-color-contrast: var(--ion-color-trade-contrast);
	--ion-color-contrast-rgb: var(--ion-color-trade-contrast-rgb);
	--ion-color-shade: var(--ion-color-trade-shade);
	--ion-color-tint: var(--ion-color-trade-tint);
}

.ion-color-prime {
	--ion-color-base: var(--ion-color-prime);
	--ion-color-base-rgb: var(--ion-color-prime-rgb);
	--ion-color-contrast: var(--ion-color-prime-contrast);
	--ion-color-contrast-rgb: var(--ion-color-prime-contrast-rgb);
	--ion-color-shade: var(--ion-color-prime-shade);
	--ion-color-tint: var(--ion-color-prime-tint);
}

.light-font-alert {
  position: fixed !important; // For fixing overlay not covering the entire screen issue (Desktop)
  .alert-wrapper .alert-message {
    color: var(--ion-color-primary-contrast);
  }
}
.index-adjust-alert {
  z-index: 99999 !important;
}
.light-font-sheet {
  //For Icon
  --color: var(--ion-color-primary-contrast) !important;
  //For Text
  color: var(--ion-color-primary-contrast) !important;
}

.ion-color-white {
  color: #ffffff!important;
}

.ion-color-tertiary {
    color: var(--ion-color-tertiary)important;
    border-color: var(--ion-color-tertiary)important;
}
.ion-color-secondary {
    color: var(--ion-color-secondary)important;
}
.ion-color-primary {
    color: var(--ion-color-primary)important;
}



