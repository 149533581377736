ion-header.mobile-header {
  --box-shadow: none;
  --border: 0;
  border-bottom-left-radius: 50px;
  height: 120px;
  font-size: 32px;
  background: #1B2831;
  
  ion-title {
    position: relative;
    text-align: left;
    padding: 0 50px 1px 20px;

    &.secondary {
      color: var(--ion-color-secondary);
    }

    .subtitle {
      font-size: 18px;
      display: block;
      color: var(--ion-color-light);
    }
  }
  
  ion-toolbar {
    --background-color: transparent;
    --box-shadow: none;
    --border: 0;
    background: transparent;
    --background: transparent;
    height: 100%;
    overflow: hidden; /* fixes swoop on iOS */
    display: flex;
  }
  
  ion-back-button, ion-icon {
    position: relative;
    left: 12px;
    z-index: 999999;
    color: var(--ion-color-primary-tint) !important;
    --icon-font-size: 30px;
  }
}