.mat-option:focus:not(.mat-option-disabled), .mat-option:hover:not(.mat-option-disabled) {
    background: #33424c;
}

.cdk-overlay-container {
  z-index: 9999999999;
}

.mat-select-panel {
    border-radius: 15px !important;
    background: transparent !important;
    overflow-y: auto !important;

    mat-option {
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-tint);

        &.mat-active {
            background: #404f5a !important;
            color: var(--ion-color-primary-tint) !important;
        }
    }
}

div.app-dropdown {
    position: relative;

    & > i {
        position: absolute;
        color: white;
        right: 10px;
        top: 14px;
    }

    mat-select {
        border: 1px solid #687984;
        border-radius: 15px;
        padding: 15px 20px;

        .mat-select-value {
            color: var(--ion-color-light);
            text-align: left;
        }

        .mat-select-arrow {
            opacity: 0;
        }
    }
}
