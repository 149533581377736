@import "../../theme/variables";

.intake {
  //background-image: url('/assets/images/img-bg-smoke.jpg');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-color: var(--ion-background-color);
  height: 100%;
  background: var(--ion-background-color);

  &.deep {
    display: flex;
    align-items: center;
  }

  &.short {
    //background-image: url('/assets/images/lodepay-logo.png');
    background-position: top center;
    background-size: 1200px;
  }

  ion-header {
    --box-shadow: none;
    --border: 0;
    background: var(--ion-background-color);
    --background: var(--ion-background-color);
  }

  ion-content {
    background-color: var(--ion-background-color);
  }

  ion-toolbar {
    --background-color: var(--ion-background-color);
    --box-shadow: none;
    --border: 0;
    background: var(--ion-background-color);
    --background: var(--ion-background-color);
  }
}

