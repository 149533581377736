.breadcrumbs {
    margin-bottom: 0;
    color: #687984;
    font-size: 25px;

    ion-icon {
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    span {
        margin-left: 16px;
        position: relative;
        bottom: 4px;
    }
}