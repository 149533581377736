ion-segment {
    border-bottom: 1px solid #576773;

    ion-segment-button {
        text-transform: capitalize;
        color: var(--ion-color-light) !important;
        --color-hover: var(--ion-color-light) !important;
        --color-checked: var(--ion-color-light) !important;
        &.segment-button-checked {
            color: var(--ion-color-secondary) !important;
            --color-hover: var(--ion-color-secondary) !important;
            --color-checked: var(--ion-color-secondary) !important;
        }
    }
}