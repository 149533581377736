// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.



@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "Roboto-fallback";
  src: url(/assets/fonts/Roboto-Regular.ttf) format("truetype");
}

$font-family-base: "Roboto", "Roboto-fallback", sans-serif !default;

/**
 * core
 */
@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-Light.eot');
    src: local('Nexa Light'), local('Nexa-Light'),
        url('../assets/fonts/Nexa-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-Light.woff2') format('woff2'),
        url('../assets/fonts/Nexa-Light.woff') format('woff'),
        url('../assets/fonts/Nexa-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-Thin.eot');
    src: local('Nexa Thin'), local('Nexa-Thin'),
        url('../assets/fonts/Nexa-Thin.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-Thin.woff2') format('woff2'),
        url('../assets/fonts/Nexa-Thin.woff') format('woff'),
        url('../assets/fonts/Nexa-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-HeavyItalic.eot');
    src: local('Nexa Heavy Italic'), local('Nexa-HeavyItalic'),
        url('../assets/fonts/Nexa-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-HeavyItalic.woff2') format('woff2'),
        url('../assets/fonts/Nexa-HeavyItalic.woff') format('woff'),
        url('../assets/fonts/Nexa-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-XBold.eot');
    src: local('Nexa XBold'), local('Nexa-XBold'),
        url('../assets/fonts/Nexa-XBold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-XBold.woff2') format('woff2'),
        url('../assets/fonts/Nexa-XBold.woff') format('woff'),
        url('../assets/fonts/Nexa-XBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-BlackItalic.eot');
    src: local('Nexa Black Italic'), local('Nexa-BlackItalic'),
        url('../assets/fonts/Nexa-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-BlackItalic.woff2') format('woff2'),
        url('../assets/fonts/Nexa-BlackItalic.woff') format('woff'),
        url('../assets/fonts/Nexa-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-BookItalic.eot');
    src: local('Nexa Book Italic'), local('Nexa-BookItalic'),
        url('../assets/fonts/Nexa-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-BookItalic.woff2') format('woff2'),
        url('../assets/fonts/Nexa-BookItalic.woff') format('woff'),
        url('../assets/fonts/Nexa-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-Bold.eot');
    src: local('Nexa Bold'), local('Nexa-Bold'),
        url('../assets/fonts/Nexa-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-Bold.woff2') format('woff2'),
        url('../assets/fonts/Nexa-Bold.woff') format('woff'),
        url('../assets/fonts/Nexa-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-BoldItalic.eot');
    src: local('Nexa Bold Italic'), local('Nexa-BoldItalic'),
        url('../assets/fonts/Nexa-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Nexa-BoldItalic.woff') format('woff'),
        url('../assets/fonts/Nexa-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-ThinItalic.eot');
    src: local('Nexa Thin Italic'), local('Nexa-ThinItalic'),
        url('../assets/fonts/Nexa-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-ThinItalic.woff2') format('woff2'),
        url('../assets/fonts/Nexa-ThinItalic.woff') format('woff'),
        url('../assets/fonts/Nexa-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-Italic.eot');
    src: local('Nexa Italic'), local('Nexa-Italic'),
        url('../assets/fonts/Nexa-Italic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-Italic.woff2') format('woff2'),
        url('../assets/fonts/Nexa-Italic.woff') format('woff'),
        url('../assets/fonts/Nexa-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-Regular.eot');
    src: local('Nexa Regular'), local('Nexa-Regular'),
        url('../assets/fonts/Nexa-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-Regular.woff2') format('woff2'),
        url('../assets/fonts/Nexa-Regular.woff') format('woff'),
        url('../assets/fonts/Nexa-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-Black.eot');
    src: local('Nexa Black'), local('Nexa-Black'),
        url('../assets/fonts/Nexa-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-Black.woff2') format('woff2'),
        url('../assets/fonts/Nexa-Black.woff') format('woff'),
        url('../assets/fonts/Nexa-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-XBoldItalic.eot');
    src: local('Nexa XBold Italic'), local('Nexa-XBoldItalic'),
        url('../assets/fonts/Nexa-XBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-XBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Nexa-XBoldItalic.woff') format('woff'),
        url('../assets/fonts/Nexa-XBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-Book.eot');
    src: local('Nexa Book'), local('Nexa-Book'),
        url('../assets/fonts/Nexa-Book.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-Book.woff2') format('woff2'),
        url('../assets/fonts/Nexa-Book.woff') format('woff'),
        url('../assets/fonts/Nexa-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-LightItalic.eot');
    src: local('Nexa Light Italic'), local('Nexa-LightItalic'),
        url('../assets/fonts/Nexa-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/Nexa-LightItalic.woff') format('woff'),
        url('../assets/fonts/Nexa-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('../assets/fonts/Nexa-Heavy.eot');
    src: local('Nexa Heavy'), local('Nexa-Heavy'),
        url('../assets/fonts/Nexa-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Nexa-Heavy.woff2') format('woff2'),
        url('../assets/fonts/Nexa-Heavy.woff') format('woff'),
        url('../assets/fonts/Nexa-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@import "scss/settings";
@import "scss/mixins";
@import "scss/core";
@import "scss/typography";
@import "scss/intake";

/**
* custom
*/
@import "scss/responsive.scss";
@import "scss/components/button.scss";
@import "scss/components/segments.scss";
@import "scss/components/card.scss";
@import "scss/components/checkbox.scss";
@import "scss/components/radio.scss";
@import "scss/components/alert.scss";
@import "scss/components/input.scss";
@import "scss/components/textarea.scss";
@import "scss/components/dropdown.scss";
@import "scss/components/select-alert.scss";
@import "scss/components/panel.scss";
@import "scss/components/breadcrumbs.scss";
@import "scss/components/toast.scss";
@import "scss/components/mobile-header.scss";


:root {
    --ion-font-family: 'Nexa', sans-serif;
}

body {
    font-family: var(--ion-font-family);
}
ion-router-outlet.dev-mode {
  height: calc(100% - 50px);
}
.modal-fullscreen {
  position: fixed;
   --background: url('../assets/images/G3_7.png') ;
}
ion-toolbar > ion-title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 90px 1px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: inherit;
  color: var(--ion-color-light);
  font-size: 30px;
  font-weight: 700;
  border-bottom: none;
}

ion-toolbar > ion-menu-button {
  z-index: 2;
  color: #333132;
  font-weight: 700;
  --background-focused: rgba(66, 66, 66, 0.24);
  --background-hover: rgba(66, 66, 66, 0.08);
  --border-radius: 50%;
  --color: initial;
  --padding-end: 8px;
  height: 40px;
  font-size: 48px;
}

.orange-button {
  --background: #f78d2b !important;
}

.orange-text {
  color: #f78d2b !important;
}

.list-ios.list-inset ion-item {
  --border-width: 0;
  --inner-border-width: 0;
}

a {
  color: var(--ion-color-secondary);

  &:hover {
    color: #23b1db;
  }

  &:visited {
    color: #129bc4;
  }
}

@media only screen and (min-width: 769px) {
  sidemenu {
    position: relative;
    z-index: 999; // TODO: make sure if it not hiding on other pages(99999)
  }
}

.sidemenu-bottom {
  background: var(--ion-color-primary);
}

.modal-wrapper {
  box-shadow: none !important;
}

.fullscreen-modal {
  --width: 100%;
  --height: 100%;
  box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.before-activation {
  --border-radius: 25px;
  margin-bottom: calc(var(--ion-safe-area-bottom) + 56px); // height matched with bottom safe area and footer+tabs
  .modal-wrapper,
  .modal-shadow {
    position: absolute;
    bottom: 0;
    border-radius: 2.5em 2.5em 0px 0px;
  }
}

.two-fa-modal {
  .modal-wrapper {
    max-width: 450px;
    box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  }
}

.tour-guide-modal {
  #ion-overlay-1 {
    width: calc(100vw - 300px) !important;
    margin-left: 300px !important;
  }
}

.scrollbar,
.mat-select-panel {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 15px;
    background: var(--ion-color-primary);
    border-radius: 26px;
  }

  /* Track */
  &::-webkit-scrollbar-thumb {
    background: #687984;
    border-radius: 26px;
    height: 44px;
    border: 6px solid var(--ion-color-primary);
  }
}

.password-icons-wrapper {
  text-align: right;
  padding-top: 2px;
  width: 90%;
  position: absolute;

  .password-input-icon {
    margin-right: 10px;
    color: var(--ion-color-gray-dusty);
    z-index: 99;
    position: relative;
  }
}

.show-modal {
  position: fixed;
}

.two-fa-modal {
 background: var(--ion-color-primary);
}

.alert-input.sc-ion-alert-md {
  color: lightgray;
}

ion-toast {
  position: fixed;
}

ion-toast.toast-comming-soon {
  position: fixed;
  text-align: center;
}

.error-shake {
  animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

#menu-content {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(8px, 0, 0);
  }
}

.global-spinner {
  color: white;
}
