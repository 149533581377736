div.alert {
    padding: 14px;
    border: 1px solid var(--ion-color-light);
    border-radius: 24px;
    text-align: center;
    color: var(--ion-color-light);

    &.success {
        color: var(--ion-color-success);
        border-color: var(--ion-color-success);
    }

    &.warning {
        color: var(--ion-color-warning);
        border-color: var(--ion-color-warning);
    }

    &.danger {
        color: var(--ion-color-danger);
        border-color: var(--ion-color-danger);
    }
}




.modal-show-connection {
    --height: 50%;
    text-align: center;
    .alert-title {
        text-align: center;
    }
    .logout.btn {
        background: #43CAF1;
    }
    .notNow.btn {
      border: 1px solid #43CAF1;
    }
}

